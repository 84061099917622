// ******************************
// Global styles for public
// ******************************
@use '@fmnts/components/corporate-identity/formunauts-design-system.fonts' as
  fonts;
@use '@fmnts/components/corporate-identity/formunauts-design-system.tokens' as
  tokens;

@include fonts.figtree();
@include fonts.chillax();

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import '@fmnts/components/core/_core';

  @include tokens.emit();
}
